<template>
    <b-card-code title="Package info Update" >
        <validation-observer ref="simpleRules">
        <b-form>
            <b-row>
                <b-col md="6">
                    <b-form-group
                        label="Package Name"
                        label-for="package-name">
                        <validation-provider
                            #default="{ errors }"
                            name="Package Name"
                            rules="required"
                        >
                            <b-form-input
                                v-model="packageInfo.package_name"
                                :state="errors.length > 0 ? false:null"
                                placeholder="Enter Package Name"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>
                <b-col md="6">
                    <b-form-group
                        label="Bandwidth"
                        label-for="bandwidth">
                        <validation-provider
                            #default="{ errors }"
                            name="Bandwidth"
                            rules="required"
                        >
                            <b-form-input
                                v-model="packageInfo.bandwidth"
                                :state="errors.length > 0 ? false:null"
                                placeholder="Enter Bandwidth"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>
                <b-col md="6">
                    <b-form-group
                        label="Validity"
                        label-for="validity">
                        <validation-provider
                            #default="{ errors }"
                            name="Validity"
                            rules="required"
                        >
                            <b-form-input
                                v-model="packageInfo.validity"
                                :state="errors.length > 0 ? false:null"
                                placeholder="Enter Validity"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>
                <b-col md="6">
                    <b-form-group
                        label="Price"
                        label-for="price">
                        <validation-provider
                            #default="{ errors }"
                            name="Price"
                            rules="required"
                        >
                            <b-form-input
                                v-model="packageInfo.price"
                                :state="errors.length > 0 ? false:null"
                                placeholder="Enter Package Price"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>
                <b-col md="6">
                    <b-form-group
                        label="Status"
                        label-for="status">
                        <validation-provider
                            #default="{ errors }"
                            name="Status"
                            rules="required"
                        >
                            <b-form-select
                                v-model="packageInfo.status"
                                :options="status"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>
                <b-col cols="12">
                    <b-button
                        variant="primary"
                        type="submit"
                        @click.prevent="validationForm"
                    >
                        Submit
                    </b-button>
                </b-col>
            </b-row>
        </b-form>
    </validation-observer>
    </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
    BFormInput, BFormGroup, BForm, BRow, BCol, BButton, BCardText,BFormSelect,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import useJwt from "@/auth/jwt/useJwt";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import router from "@/router";
import {PACKAGE_VIEW, ROUTER_LIST, ROUTER_VIEW} from "@core/services/api";
import Mixin from "@core/services/mixin";
import {checkRes} from "@core/services/helper";

export default {
    mixins: [
        Mixin
    ],
    components: {
        BFormSelect,
        BCardCode,
        ValidationProvider,
        ValidationObserver,
        BCardText,
        BFormInput,
        BFormGroup,
        BForm,
        BRow,
        BCol,
        BButton,
    },
    data() {
        return {
            packageId:router.currentRoute.params.packageId,
            packageInfo:'null',
            status: [
                { value: null, text: 'Please select status' },
                { value: '1', text: 'Active' },
                { value: '0', text: 'Inactive' }
            ],
            emailValue: '',
            name: '',
            required,
            email,
        }
    },
    methods: {
        getRouterData() {
            this.$http.get(process.env.VUE_APP_BASEURL+  PACKAGE_VIEW + this.packageId)
                .then(res => {
                    this.packageInfo = res.data.data
                }).catch(error => {
                console.log(error)
            })
        },
        validationForm() {
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                   useJwt.updatePackage(this.packageId,{
                    //    isp_id:6,
                       package_name: this.packageInfo.package_name,
                       bandwidth: this.packageInfo.bandwidth,
                       validity: this.packageInfo.validity,
                       price: this.packageInfo.price,
                       status: this.packageInfo.status
                    }).then(response => {
                       if (checkRes(response.data.code)) {
                           this.$router.push({name: 'isp-package-edit'})
                               .catch(error => {
                                   this.toastMessage('success','Package Setting', response)
                               })
                       } else {
                           this.$router.push({name: 'isp-package-edit'})
                               .catch(error => {
                                   this.toastMessage('warning','Package Setting', response)
                               })
                       }
                   })
                }
            })
        },
    },
    mounted() {
    this.getRouterData()
    }
}
</script>
